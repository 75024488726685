<template>
  <div>
    <Header />
    <section class="help-search-support">
      <div class="content">
        <div class="head">
          <h2>How to submit dApps?</h2>
          <p>Learn how to submit dApps.</p>
        </div>
      </div>
    </section>

    <section class="about-section">
      <div class="descriptio">
        <h2>How to submit dApps</h2>
        <p>First you must give dapps information to Dino Team</p>
        <ul>
          <li>Project Name</li>
          <li>Dapps url</li>
          <li>Network Support</li>
          <li>Category</li>
          <li>Project Tagline</li>
          <li>Short Description</li>
          <li>Description</li>
          <li>
            Icon Url <br />
            <b>(Must be 100x100 or 200x200, jpg, png, svg, webp format)</b>
          </li>
          <li>
            Social Media & Document URL (facebook, telegram, medium, discord,
            github, whitepaper)
          </li>
        </ul>
        <p>
          After Information Above already, you can send to Dino Team, (Click +
          button)
        </p>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "HowToSubmit",
  components: {
    Header,
    Footer,
  },
  created() {
    this.$mixpanel.track("Visit How to submit");
  },
};
</script>

<style></style>
